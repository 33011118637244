import { DialogStore, useDialogStore } from '../stores'
import { DialogFooter } from '../componentsV2'
import { ComponentProps } from 'react'
import { QuotaType } from '@bpinternal/const'
import { Dialog, Text } from '@radix-ui/themes'
import { QUOTA_TYPE_USER_FACING_PROPERTIES_MAP } from '../features/usage/constants'
import { Link } from '../elementsv2'

type CustomDialogOptions = Partial<Pick<DialogStore, 'content' | 'title'>>

type ConfirmationDialogOptions = CustomDialogOptions & ComponentProps<typeof DialogFooter>

const defaultCustomDialogOptions: CustomDialogOptions = {
  content: null,
  title: '',
}

type Props = Partial<
  Pick<DialogStore, 'cancelLabel' | 'confirmLabel' | 'onCancel' | 'onConfirm' | 'variant' | 'disabled'> & {
    showCancel?: boolean
  }
>

export const defaultDialogFooterProps: Props = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  onCancel: () => {},
  onConfirm: () => {},
  variant: 'primary',
  disabled: false,
  showCancel: true,
}

const defaultConfirmationDialogOptions: ConfirmationDialogOptions = {
  ...defaultCustomDialogOptions,
  ...defaultDialogFooterProps,
}

export function showCustomDialog(options?: CustomDialogOptions) {
  useDialogStore.setState({
    show: true,
    ...defaultCustomDialogOptions,
    ...(options ?? {}),
  })
}

export function showConfirmationDialog(options?: ConfirmationDialogOptions) {
  const { content, ...dialogOptions } = { ...defaultConfirmationDialogOptions, ...(options ?? {}) }

  useDialogStore.setState({
    show: true,
    ...dialogOptions,
    content: (
      <>
        {content}
        <DialogFooter {...dialogOptions} />
      </>
    ),
  })
}

export function showAsyncConfirmationDialog<T>(
  options?: Omit<ConfirmationDialogOptions, 'onConfirm' | 'onCancel'> & {
    onConfirm?: () => Promise<T>
    onCancel?: () => Promise<void>
  }
) {
  const setIsLoading = useDialogStore.getState().setIsLoading
  const update = useDialogStore.getState().update
  const hide = useDialogStore.getState().setShow.bind(null, false)
  const { content, ...dialogOptions } = {
    ...defaultConfirmationDialogOptions,
    ...(options ?? {}),
    onConfirm: () => {
      update({ disabled: true, loading: true })
      return (
        options
          ?.onConfirm?.()
          .then(hide)
          .finally(() => setIsLoading(false)) ?? hide()
      )
    },
    onCancel: () => {
      update({ disabled: true, loading: true })
      return (
        options
          ?.onCancel?.()
          .then(hide)
          .finally(() => setIsLoading(false)) ?? hide()
      )
    },
  }

  useDialogStore.setState({
    show: true,
    ...dialogOptions,
    content: (
      <>
        {content}
        <DialogFooter {...dialogOptions} selfClose={false} />
      </>
    ),
  })
}

//TODO: This could use some refinement, but it's a good start
export function showUpsellDialog({
  quota,
  workspaceId,
  onConfirm,
}: {
  quota: QuotaType
  workspaceId: string
  onConfirm: () => void
}) {
  showConfirmationDialog({
    title: 'Upgrade your plan',
    cancelLabel: 'Not now',
    confirmLabel: 'Upgrade',
    onConfirm,
    content: (
      <Text>
        You have reached your {QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[quota].name} quota. Please{' '}
        <Dialog.Close>
          <Link to="/workspaces/$workspaceId/settings/billing/plans" params={{ workspaceId }}>
            upgrade your plan
          </Link>
        </Dialog.Close>{' '}
        or add an{' '}
        <Dialog.Close>
          <Link to="/workspaces/$workspaceId/settings/billing" params={{ workspaceId }}>
            add-on
          </Link>
        </Dialog.Close>{' '}
        to continue using this feature.
      </Text>
    ),
  })
}
