import { create } from 'zustand'
import { blue } from '@radix-ui/colors'
import { generateThemeStylesheet } from '@botpress/webchat'

export type WebchatStore = {
  theme: {
    primaryColor: string
    themeMode: 'light' | 'dark'
    borderRadiusScale: number
    variant: 'soft' | 'solid'
    fontFamily: 'rubik' | 'inter' | 'ibm' | 'fira'
  }
  isDirty: boolean
  setDirty: (dirty: boolean) => void
  stylesheet: string
  additionalStylesheet: string
  setTheme: (theme: Partial<WebchatStore['theme']> & { additionalStylesheet?: string }) => void
  updateStylesheet: () => void
  setPrimaryColor: (color: string) => void
  setThemeMode: (theme: 'light' | 'dark') => void
  setBorderRadiusScale: (radius: number) => void
  setVariant: (variant: 'soft' | 'solid') => void
  setFontFamily: (font: 'rubik' | 'inter' | 'ibm' | 'fira') => void
  setExtraStyles: (styles: string) => void
}

const defaultThemeValues: WebchatStore['theme'] = {
  primaryColor: blue.blue8,
  themeMode: 'light',
  borderRadiusScale: 1,
  variant: 'soft',
  fontFamily: 'inter',
}

export const useWebchatStore = create<WebchatStore>((set, get) => ({
  theme: defaultThemeValues,
  configs: {
    name: '',
    description: '',
    composerPlaceholder: '',
    showPoweredBy: false,
    email: '',
    website: '',
    phoneNumber: '',
    avatarUrl: '',
    termsConditions: '',
    privacyPolicy: '',
    stylesheet: '',
  },
  stylesheet: '',
  additionalStylesheet: '',
  isDirty: false,
  setDirty: (dirty) => set({ isDirty: dirty }),
  setTheme: ({ additionalStylesheet, ...theme }) => {
    const store = get()
    set({
      theme: {
        ...defaultThemeValues,
        ...theme,
      },
      additionalStylesheet: additionalStylesheet ?? store.additionalStylesheet,
    })
    store.updateStylesheet()
  },

  setPrimaryColor: (color) => {
    const store = get()

    set({
      theme: {
        ...store.theme,
        primaryColor: color,
      },
    })
    store.updateStylesheet()
    store.setDirty(true)
  },
  setThemeMode: (theme) => {
    const store = get()
    set({
      theme: {
        ...store.theme,
        themeMode: theme,
      },
    })
    store.updateStylesheet()
    store.setDirty(true)
  },

  setVariant: (variant) => {
    const store = get()
    set({
      theme: {
        ...store.theme,
        variant,
      },
    })
    store.updateStylesheet()
    store.setDirty(true)
  },
  setBorderRadiusScale: (radius) => {
    const store = get()
    set({
      theme: {
        ...store.theme,
        borderRadiusScale: radius,
      },
    })
    store.updateStylesheet()
    store.setDirty(true)
  },
  setFontFamily: (font) => {
    const store = get()
    set({
      theme: {
        ...store.theme,
        fontFamily: font,
      },
    })
    store.updateStylesheet()
    store.setDirty(true)
  },
  setExtraStyles: (styles) => {
    const store = get()
    set({
      additionalStylesheet: styles,
    })
    store.updateStylesheet()
    store.setDirty(true)
  },
  updateStylesheet: () => {
    const store = get()
    const { borderRadiusScale: radius, fontFamily, variant, themeMode, primaryColor: color } = store.theme
    set({
      stylesheet: `${generateThemeStylesheet({ color, fontFamily, radius, themeMode, variant })}\n${store.additionalStylesheet}`,
    })
  },
}))
