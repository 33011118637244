import { useSuspenseQuery, type QueryClient } from '@tanstack/react-query'
import { Outlet, useParams, ScrollRestoration, createRootRouteWithContext } from '@tanstack/react-router'
import { useAppStore, usePortalStore } from '../stores'
import { workspacesQueryOptions } from '../queries'
import { TopLevelNav } from '~/layoutsV2/TopLevelNav'
import { SecondLevelNav } from '~/layoutsV2/SecondLevelNav'
import { Box, Flex } from '@radix-ui/themes'
import { useCurrentRouteId } from '../hooks/useCurrentRouteId'
import { AppFooter } from '~/layoutsV2/AppFooter'
import { DialogProvider } from '../providers/DialogProvider'
import { useEffect } from 'react'
import { trackPage } from '../providers'

type RouterContext = {
  queryClient: QueryClient
}

export const Route = createRootRouteWithContext<RouterContext>()({
  beforeLoad: async () => {
    const { currentUser: user } = useAppStore.getState()
    if (!user) {
      throw new Error('Unabled to get current user')
    }
    return { user }
  },
  loader: ({ context }) => context.queryClient.ensureQueryData(workspacesQueryOptions()),
  component: RootComponent,
})

function RootComponent() {
  const { data: workspaces } = useSuspenseQuery(workspacesQueryOptions())
  const routeId = useCurrentRouteId()

  const bannerPortalRef = usePortalStore((state) => state.bannerPortalRef)

  const { workspaceId, botId, conversationId } = useParams({ strict: false })

  const secondNavType = routeId?.includes('/workspaces/$workspaceId/bots/$botId')
    ? 'bot'
    : routeId?.includes('/workspaces/$workspaceId')
      ? 'workspace'
      : 'none'

  useEffect(() => {
    if (routeId) {
      trackPage({ name: routeId, workspaceId, botId, conversationId })
    }
  }, [routeId])

  return (
    <>
      <ScrollRestoration />
      <DialogProvider />
      <Flex p="3" pb="2" direction="column" gap="4" className="sticky top-0 z-[1] border-b border-b-gray-5 bg-gray-2">
        <TopLevelNav workspaces={workspaces} />
        <SecondLevelNav
          type={secondNavType}
          currentWorkspaceId={workspaceId}
          currentBotId={botId}
          currentRouteId={routeId}
        />
      </Flex>
      <Box ref={bannerPortalRef} />
      <Outlet />
      {/* TODO: Temporary hack for initial release until we remove dashboard v1 and fix the position on the component */}
      {!(routeId?.includes('issues') || routeId?.includes('logs')) && <AppFooter />}
    </>
  )
}
