import { Flex } from '@radix-ui/themes'
import { createFileRoute } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { getApiClientForBot } from '~/client'
import { CodeSnippet } from '~/components'
import { Callout } from '~/elementsv2'
import { ConfigSection } from '~/features/webchat/components'
import { useWebchatConfig } from '~/hooks'
import { config } from '~/shared'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/v2/share')({
  component: Component,
})

function Component() {
  const { botId, workspaceId } = Route.useParams()
  const { config: webchatConfig, updateWebchatIntegration } = useWebchatConfig({ botId, workspaceId })

  const [embedScript, setEmbedScript] = useState<string | null | undefined>(undefined)
  const [shareableLink, setShareableLink] = useState<string | null | undefined>(undefined)

  useAsync(async () => {
    const injectScriptFiles = await getApiClientForBot({ botId, workspaceId }).listFiles({
      tags: { webchatInjectConfigVersion: '1' },
    })

    const webchatConfigFileUrl = injectScriptFiles.files[0]?.url
    const script = webchatConfigFileUrl
      ? `
    <script src="${config.cdnBaseUrl}/webchat/v2.2/inject.js"></script>\n<script src="${webchatConfigFileUrl}"></script>
    `
      : null
    setEmbedScript(script)

    const shareableConfigFile = await getApiClientForBot({ botId, workspaceId }).listFiles({
      tags: { webchatConfigVersion: '1' },
    })

    const shareableConfigFileUrl = shareableConfigFile.files[0]?.url
    const shareableLink = shareableConfigFileUrl
      ? `${config.cdnBaseUrl}/webchat/v2.2/shareable.html?configUrl=${shareableConfigFileUrl}`
      : null
    setShareableLink(shareableLink)
  }, [])

  // const shareableLink = useMemo(() => `${config.cdnBaseUrl}/webchat/v2.2/shareable.html?botId=${botId}`, [botId])

  // This is there to check is the file is in our S3 bucket, if not we create it by calling updateBot
  useEffect(() => {
    fetch(`${config.mediaBaseUrl}/${botId}/webchat/v2.2/config.js`).then((response) => {
      if (!response.ok) {
        updateWebchatIntegration(webchatConfig, { showToast: false })
      }
    })
  }, [])

  return (
    <Flex direction={'column'} gap={'6'}>
      <ConfigSection
        title="Shareable Link"
        description="Share the following link with people that would like to quickly test your chatbot."
        linkUrl={shareableLink ?? ''}
        linkLabel="View"
      >
        {shareableLink === null ? (
          <Callout color="blue">Your webchat is not yet configured. Please configure it first.</Callout>
        ) : (
          <CodeSnippet code={shareableLink ?? ''} />
        )}
      </ConfigSection>
      <ConfigSection
        title="Embed code"
        description="Copy and paste this code on your webpage."
        linkUrl="https://botpress.com/docs/developers/webchat-v2/embedded/"
        linkLabel="Documentation"
      >
        {embedScript === null ? (
          <Callout color="blue">Your webchat is not yet configured. Please configure it first.</Callout>
        ) : (
          <CodeSnippet code={embedScript ?? ''} />
        )}
      </ConfigSection>
    </Flex>
  )
}
