// this file was automatically generated, do not edit
/* eslint-disable */

export interface ListUsageActivityRequestHeaders {}

export interface ListUsageActivityRequestQuery {
  type:
    | "invocation_timeout"
    | "invocation_calls"
    | "storage_count"
    | "bot_count"
    | "knowledgebase_vector_storage"
    | "workspace_ratelimit"
    | "table_row_count"
    | "workspace_member_count"
    | "integrations_owned_count"
    | "ai_spend"
    | "openai_spend"
    | "bing_search_spend"
    | "always_alive";
  nextToken?: string;
}

export interface ListUsageActivityRequestParams {
  id: string;
}

export interface ListUsageActivityRequestBody {}

export type ListUsageActivityInput = ListUsageActivityRequestBody & ListUsageActivityRequestHeaders & ListUsageActivityRequestQuery & ListUsageActivityRequestParams

export type ListUsageActivityRequest = {
  headers: ListUsageActivityRequestHeaders;
  query: ListUsageActivityRequestQuery;
  params: ListUsageActivityRequestParams;
  body: ListUsageActivityRequestBody;
}

export const parseReq = (input: ListUsageActivityInput): ListUsageActivityRequest & { path: string } => {
  return {
    path: `/v1/admin/usages/${encodeURIComponent(input['id'])}/activity`,
    headers: {  },
    query: { 'type': input['type'], 'nextToken': input['nextToken'] },
    params: { 'id': input['id'] },
    body: {  },
  }
}

export interface ListUsageActivityResponse {
  data: {
    timestamp: string;
    value: number;
    period: string;
    metadata: {
      [k: string]: any | null;
    };
  }[];
  meta: {
    nextToken?: string;
  };
}

