import { Flex, Text } from '@radix-ui/themes'
import { Link, LinkProps } from '@tanstack/react-router'
import { Button, Icon } from '../elementsv2'
import { cn } from '../utils'
import type { FC, ReactNode } from 'react'

export type NavbarItem = {
  text: string
  leadingIcon?: FC<{ className?: string; size?: number; strokeWidth?: number }>
  trailingItem?: ReactNode
  to: LinkProps['to']
  active?: boolean
}

type Props = {
  workspaceId?: string
  botId?: string
  integrationId?: string
  items: NavbarItem[]
  direction?: 'row' | 'column'
  className?: string
}

export const Navbar = ({ workspaceId, botId, integrationId, items, direction, ...props }: Props) => {
  return (
    <Flex {...props} position={'relative'} gap="1" direction={direction}>
      {items.map(({ to, text, leadingIcon, active, trailingItem }) => (
        <Flex direction={'column'} gap={'2'} key={to}>
          <Link preload={active ? false : undefined} to={to} params={{ workspaceId, botId, integrationId }} search={{}}>
            <Button
              variant="minimal"
              color="gray"
              tabIndex={-1}
              className={cn({
                'bg-gray-4 text-gray-12': active,
                'w-full justify-start': direction === 'column',
              })}
            >
              {leadingIcon && <Icon icon={leadingIcon} className={cn('flex-none', { 'text-gray-12': active })} />}
              <Text>{text}</Text>
              {trailingItem}
            </Button>
          </Link>
        </Flex>
      ))}
    </Flex>
  )
}
