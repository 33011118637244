import { ClientReturn } from 'botpress-client'
import { Variant } from '../../../types'
import { Action } from '../types'

type AuditRecord = ClientReturn<'getAuditRecords'>['records'][number]

type AuditMessage = {
  label: string
  variant: Variant
  render: (audit: AuditRecord) => React.ReactNode
  getMessageString: (audit: AuditRecord) => string
}

export const auditMessages: { [key in Action]: AuditMessage } = {
  [Action.UNKNOWN]: {
    label: 'Unknown',
    variant: 'default',
    render: () => 'performed an unknown action',
    getMessageString: (audit) => `${audit.userEmail} performed an unknown action`,
  },
  [Action.ADD_WORKSPACE_MEMBER]: {
    label: 'Added member to workspace',
    variant: 'info',
    render: (audit) => (
      <>
        added <b>{audit.resourceName}</b> as member of this workspace
      </>
    ),
    getMessageString: (audit) => `${audit.userEmail} added member ${audit.resourceName} to this workspace`,
  },
  [Action.REMOVE_WORKSPACE_MEMBER]: {
    label: 'Removed member from workspace',
    variant: 'warning',
    render: (audit) => (
      <>
        removed member <b>{audit.resourceName}</b> from this workspace
      </>
    ),
    getMessageString: (audit) => `${audit.userEmail} removed member ${audit.resourceName} from this workspace`,
  },
  [Action.UPDATE_WORKSPACE_MEMBER]: {
    label: 'Updated workspace member',
    variant: 'info',
    render: (audit) => (
      <>
        changed the role of the workspace member <b>{audit.resourceName}</b>
      </>
    ),
    getMessageString: (audit) => `${audit.userEmail} changed the role of the workspace member ${audit.resourceName}`,
  },
  [Action.CREATE_WORKSPACE]: {
    label: 'Created a workspace',
    variant: 'info',
    render: () => <>created the workspace</>,
    getMessageString: (audit) => `${audit.userEmail} created the workspace`,
  },
  [Action.UPDATE_WORKSPACE]: {
    label: 'Updated a workspace',
    variant: 'info',
    render: () => <>updated the workspace information</>,
    getMessageString: (audit) => `${audit.userEmail} updated the workspace information`,
  },
  [Action.DELETE_WORKSPACE]: {
    label: 'Deleted a workspace',
    variant: 'danger',
    render: () => <>closed the workspace</>,
    getMessageString: (audit) => `${audit.userEmail} closed the workspace`,
  },
  [Action.CREATE_BOT]: {
    label: 'Created a bot',
    variant: 'info',
    render: (audit) => (
      <>
        created a bot named <b>{audit.resourceName}</b>
      </>
    ),
    getMessageString: (audit) => `${audit.userEmail} created a bot named ${audit.resourceName}`,
  },
  [Action.DOWNLOAD_BOT_ARCHIVE]: {
    // TODO obsolete - remove when all v1 code is gone
    label: 'Downloaded a bot archive',
    variant: 'info',
    render: (audit) => (
      <>
        downloaded the bot archive for <b>{audit.resourceName}</b>
      </>
    ),
    getMessageString: (audit) => `${audit.userEmail} downloaded the bot archive for ${audit.resourceName}`,
  },
  [Action.UPDATE_BOT]: {
    label: 'Updated a bot',
    variant: 'info',
    render: (audit) => (
      <>
        updated the bot information of <b>{audit.resourceName}</b>
      </>
    ),
    getMessageString: (audit) => `${audit.userEmail} updated the bot information of ${audit.resourceName}`,
  },
  [Action.UPDATE_BOT_CHANNEL]: {
    label: 'Updated a bot channel',
    variant: 'info',
    render: (audit) => (
      <>
        updated a bot channel for <b>{audit.resourceName}</b>
      </>
    ),
    getMessageString: (audit) => `${audit.userEmail} updated a bot channel for ${audit.resourceName}`,
  },
  [Action.UPDATE_BOT_CONFIG]: {
    label: 'Updated a bot config',
    variant: 'info',
    render: (audit) => (
      <>
        updated the bot config for <b>{audit.resourceName}</b>
      </>
    ),
    getMessageString: (audit) => `${audit.userEmail} updated the bot config for ${audit.resourceName}`,
  },
  [Action.DELETE_BOT]: {
    label: 'Deleted a bot',
    variant: 'danger',
    render: (audit) => (
      <>
        deleted a bot named <b>{audit.resourceName}</b>
      </>
    ),
    getMessageString: (audit) => `${audit.userEmail} deleted a bot named ${audit.resourceName}`,
  },
  [Action.DEPLOY_BOT]: {
    label: 'Published a bot',
    variant: 'info',
    render: (audit) => (
      <>
        published a bot named <b>{audit.resourceName}</b>
      </>
    ),
    getMessageString: (audit) => `${audit.userEmail} published a bot named ${audit.resourceName}`,
  },
  [Action.TRANSFER_BOT]: {
    label: 'Moved a bot to another workspace',
    variant: 'info',
    render: (audit) => (
      <>
        transferred the bot <b>{audit.resourceName}</b>
      </>
    ),
    getMessageString: (audit) => `${audit.userEmail} transferred the bot ${audit.resourceName}`,
  },
  [Action.UPDATE_PAYMENT_METHOD]: {
    label: 'Updated a payment method',
    variant: 'info',
    render: () => <>updated the payment method of the workspace</>,
    getMessageString: (audit) => `${audit.userEmail} updated the payment method of the workspace`,
  },
  [Action.SET_SPENDING_LIMIT]: {
    label: 'Updated the spending limit',
    variant: 'info',
    render: () => <>updated the spending limit</>,
    getMessageString: (audit) => `${audit.userEmail} updated the spending limit`,
  },
  [Action.SET_AI_SPENDING_LIMIT]: {
    label: 'Updated the AI spend limit',
    variant: 'info',
    render: () => <>updated the AI spend limit</>,
    getMessageString: (audit) => `${audit.userEmail} updated the AI spend limit to $${audit.value}`,
  },
  [Action.UPDATE_WORKSPACE_BILLING_READONLY]: {
    label: 'Updated billing',
    variant: 'info',
    render: (audit) => <>{audit.value === 'true' ? 'enabled' : 'disabled'} readonly billing</>,
    getMessageString: (audit) =>
      `${audit.userEmail} ${audit.value === 'true' ? 'enabled' : 'disabled'} readonly billing for this workspace`,
  },
}
