import { getClient, WebchatProvider, Webchat, Fab } from '@botpress/webchat'
import { Badge, Flex, Section, Text } from '@radix-ui/themes'
import { Link, Outlet, createFileRoute } from '@tanstack/react-router'
import { useEffect, useLayoutEffect } from 'react'
import { EmptyState as LegacyEmptyState } from '~/components'
import { Boundrary, Navbar, type NavbarItem } from '~/componentsV2'
import { Button, Card, EmptyState, Page, Select, Spinner } from '~/elementsv2'
import { hasUnmigratedConfigs } from '~/features/webchat/schemas'
import { migrateConfigs } from '~/features/webchat/utils'
import {
  useCurrentRouteId,
  useIntegration,
  useIsAuthorized,
  useUpdateBot,
  useWebchatConfig,
  useWebchatV2,
} from '~/hooks'
import { SidebarLayout } from '~/layouts'
import { useSuspenseQuery } from '~/services'
import { getCdmStudioUrl, config as globalConfig } from '~/shared/configurations'
import { useWebchatStore } from '~/stores'

const currentRoute = '/workspaces/$workspaceId/bots/$botId/webchat' as const

export const Route = createFileRoute(currentRoute)({
  component: () => <Component />,
})

function Component() {
  const { botId, workspaceId } = Route.useParams()
  const { user } = Route.useRouteContext()
  const webchatIntegration = useIntegration({ name: 'webchat', version: 'latest' }).data
  const { config, updateWebchatIntegration } = useWebchatConfig({ botId, workspaceId })
  const { isWebchatV2Enabled, setIsWebchatV2Enabled } = useWebchatV2()
  const isAuthorized = useIsAuthorized({ workspaceId, userId: user.id })

  const setTheme = useWebchatStore((state) => state.setTheme)

  useEffect(() => {
    // TODO: Fix this any cast
    setTheme({ ...config, fontFamily: config.fontFamily as any })
  }, [JSON.stringify(config)])

  useLayoutEffect(() => {
    if (hasUnmigratedConfigs(config) && isWebchatV2Enabled && isAuthorized('bots.update')) {
      updateWebchatIntegration(migrateConfigs(config), { showToast: false })
    }
  }, [])

  const bot = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId }).data
  if (!Object.values(bot.integrations).find((integration) => integration.name === 'webchat')) {
    return (
      <Page title={'Webchat'}>
        <Section size={'4'}>
          <EmptyState
            title="Webchat not installed"
            description="You must install the Webchat integration to interact with it."
            primaryAction={
              <Link to="/hub/integrations/$integrationId" params={{ integrationId: webchatIntegration?.id ?? '' }}>
                <Button>Install Webchat</Button>
              </Link>
            }
          />
        </Section>
      </Page>
    )
  }

  return (
    <>
      <Page
        title={
          <Flex gap={'2'} align={'center'}>
            <Text>Webchat</Text>
            <Select
              size={'1'}
              value={isWebchatV2Enabled ? 'v2' : 'v1'}
              onValueChange={(value) => setIsWebchatV2Enabled(value === 'v2')}
              items={[
                { type: 'item', content: 'Version 1', value: 'v1' },
                { type: 'item', content: 'Version 2', value: 'v2' },
              ]}
            />
          </Flex>
        }
      >
        <SidebarLayout
          main={<Outlet />}
          leftSidebar={<LeftSidebar />}
          rightSidebarSize="5"
          rightSidebar={
            <Boundrary>
              <RightSidebar />
            </Boundrary>
          }
        />
      </Page>
    </>
  )
}

const LeftSidebar = () => {
  const sidebarMenu = ['general', 'theme', 'share', 'advanced-settings'] as const
  const { workspaceId, botId } = Route.useParams()
  const location = useCurrentRouteId() ?? ''

  const navVersion = location.includes('/v2') ? 'v2' : 'v1'

  const items: NavbarItem[] = sidebarMenu.map((item) => ({
    text: item.replace('-', ' '),
    to: `/workspaces/$workspaceId/bots/$botId/webchat/${navVersion}/${item}`,
    trailingItem:
      item === 'theme' ? (
        <Badge color="green" variant="outline">
          New
        </Badge>
      ) : undefined,
    active: location.includes(item),
  }))

  return <Navbar className="capitalize" items={items} workspaceId={workspaceId} botId={botId} direction="column" />
}

const RightSidebar = () => {
  const { workspaceId, botId } = Route.useParams()
  const { user } = Route.useRouteContext()
  const bot = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId }).data

  const { data: webchatIntegration } = useIntegration({ name: 'webchat', version: '0.2.0' })
  const { data: script } = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_/webchat', {
    botId,
    workspaceId,
    type: 'fullscreen',
  })
  const { mutate: updateBot } = useUpdateBot()

  const { isWebchatV2Enabled } = useWebchatV2()

  const webchatInstalledIntegration = Object.values(bot.integrations).find(
    (integration) => integration.name === 'webchat'
  )

  const { config } = useWebchatConfig({
    botId,
    workspaceId,
  })

  const client = getClient({
    apiUrl: globalConfig.webchatUrl,
    clientId: webchatInstalledIntegration?.webhookId ?? '',
  })
  const isAuthorized = useIsAuthorized({ workspaceId, userId: user.id })
  const isAuthorizedToOpenStudio = isAuthorized('studio.view') || isAuthorized('studio.edit')

  const stylesheet = useWebchatStore((state) => state.stylesheet)

  if (!bot.deployedAt) {
    return (
      <Card className="flex h-200 items-center px-8">
        <EmptyState
          iconSize={3}
          title={'Chatbot not published'}
          description={'You must publish the chatbot from Botpress Studio to be able to interact with it.'}
          primaryAction={
            isAuthorizedToOpenStudio ? (
              <a href={getCdmStudioUrl(bot.id)} target="_blank" rel="noopener noreferrer">
                <Button disabled={!isAuthorizedToOpenStudio}>Edit in studio</Button>
              </a>
            ) : undefined
          }
        />
      </Card>
    )
  }

  function enableWebchat(webchatIntegrationId: string) {
    updateBot({
      id: bot.id,
      workspaceId,
      integrations: { [webchatIntegrationId]: { enabled: true, configuration: {} } },
    })
  }

  if (!(script && webchatIntegration)) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (!bot.integrations[webchatIntegration?.id ?? '']?.enabled) {
    // TODO: Fix this emptyState
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <LegacyEmptyState
          icon="NoMessages"
          title="Webchat disabled"
          message="You must enable the Webchat integration to interact with it."
        />
        <div className="flex flex-1">
          <div>
            <Button onClick={() => enableWebchat(webchatIntegration.id)}>Enable Webchat</Button>
          </div>
        </div>
      </div>
    )
  }

  if (isWebchatV2Enabled && client) {
    return (
      <Flex direction={'column'} gap={'4'} className="h-200">
        <WebchatProvider
          allowFileUpload={config.allowFileUpload}
          key={JSON.stringify(config)}
          configuration={{
            botName: config.botDisplayName,
            botAvatar: config.botAvatarUrl,
            botDescription: config.botDescription,
            color: config.primaryColor,
            fontFamily: config.fontFamily as any,
            composerPlaceholder: config.botComposerPlaceholder,
            email: config.descriptionEmailAddress,
            phone: config.descriptionPhoneNumber,
            website: config.descriptionWebsiteUrl,
            privacyPolicy: config.privacyPolicyUrl,
            termsOfService: config.termsConditionsUrl,
            variant: config.variant,
            showPoweredBy: config.showPoweredBy,
          }}
          client={client}
        >
          <Webchat />
          <div className="ml-auto">
            <Fab />
          </div>
          <style>{stylesheet}</style>
        </WebchatProvider>
      </Flex>
    )
  }

  return (
    <div className="relative h-full min-h-200 w-full overflow-clip px-0 py-0">
      <iframe title="webchat" width="100%" height="100%" srcDoc={`<body>${script}</body>`} />
    </div>
  )
}
