import { Dialog, Flex } from '@radix-ui/themes'
import React from 'react'
import { DialogStore, useDialogStore } from '../stores'
import { Button } from '~/elementsv2'
import { ThemeColor } from '~/providers/ThemeProvider'
import { defaultDialogFooterProps } from '~/services'

type Props = Partial<
  Pick<DialogStore, 'cancelLabel' | 'confirmLabel' | 'onCancel' | 'onConfirm' | 'variant' | 'disabled' | 'loading'> & {
    showCancel?: boolean
    selfClose?: boolean
  }
>

export const DialogFooter = (props: Props) => {
  const { showCancel = true, selfClose = true } = { ...defaultDialogFooterProps, ...props }

  const { cancelLabel, confirmLabel, onCancel, onConfirm, variant, disabled, loading } = {
    ...defaultDialogFooterProps,
    ...useDialogStore((state) => state),
    ...props,
  }

  return (
    <Flex gap="3" mt="4" justify="end">
      {showCancel && selfClose ? (
        <Dialog.Close disabled={loading}>
          <Button onClick={onCancel} variant="soft" color="gray">
            {cancelLabel}
          </Button>
        </Dialog.Close>
      ) : (
        <Button onClick={onCancel} disabled={loading} variant="soft" color="gray">
          {cancelLabel}
        </Button>
      )}

      {selfClose ? (
        <Dialog.Close disabled={disabled || loading}>
          <Button
            onClick={onConfirm}
            variant="solid"
            loading={loading}
            color={variant === 'primary' ? ThemeColor : variant === 'danger' ? 'red' : 'gray'}
          >
            <span>{confirmLabel}</span>
          </Button>
        </Dialog.Close>
      ) : (
        <Button
          disabled={disabled || loading}
          loading={loading}
          onClick={onConfirm}
          variant="solid"
          color={variant === 'primary' ? ThemeColor : variant === 'danger' ? 'red' : 'gray'}
        >
          <span>{confirmLabel}</span>
        </Button>
      )}
    </Flex>
  )
}
