import { P, match } from 'ts-pattern'
import { z } from 'zod'

// This is copied from the backend
const themeNames = ['prism', 'galaxy', 'dusk', 'eggplant', 'dawn', 'midnight'] as const
export type ThemeName = (typeof themeNames)[number]

export const webchatIntegrationConfigSchema = z.object({
  botName: z.string().optional(),
  avatarUrl: z.string().optional(),
  botConversationDescription: z.string().optional(),
  phoneNumber: z.string().optional(),
  termsConditions: z.string().optional(),
  privacyPolicy: z.string().optional(),
  emailAddress: z.string().optional(),
  website: z.string().optional(),
  showBotInfoPage: z.boolean().optional(),
  enableConversationDeletion: z.boolean().optional(),
  composerPlaceholder: z.string().optional(),
  stylesheet: z.string().optional(),
  useSessionStorage: z.boolean().optional(),
  showPoweredBy: z.boolean().optional(),
  frontendVersion: z.enum(['v0', 'v1']).catch(() => 'v0' as const),
  theme: z.enum(themeNames).catch(() => 'prism' as const),
  themeColor: z.string().optional(),
  additionalStyles: z.string().optional(),
  allowedOrigins: z.array(z.string()).optional(),
  botDisplayName: z.string().optional(),
  botAvatarUrl: z.string().optional(),
  botDescription: z.string().optional(),
  descriptionPhoneNumber: z.object({ title: z.string().optional(), link: z.string().optional() }).optional(),
  descriptionEmailAddress: z.object({ title: z.string().optional(), link: z.string().optional() }).optional(),
  descriptionWebsiteUrl: z.object({ title: z.string().optional(), link: z.string().optional() }).optional(),
  termsConditionsUrl: z.object({ title: z.string().optional(), link: z.string().optional() }).optional(),
  privacyPolicyUrl: z.object({ title: z.string().optional(), link: z.string().optional() }).optional(),
  botComposerPlaceholder: z.string().optional(),
  showPoweredByBotpress: z.boolean().optional(),
  additionalStylesheet: z.string().optional(),
  primaryColor: z.string().optional(),
  borderRadiusScale: z.number().optional(),
  themeMode: z.enum(['light', 'dark']).optional(),
  variant: z.enum(['solid', 'soft']).optional(),
  fontFamily: z.string().optional(),
  allowFileUpload: z.boolean().optional(),
})

// This schema is used to validate if the webchat integration config has been migrated to the new styler format
export function hasUnmigratedConfigs(config: WebchatIntegrationConfig) {
  return match(config)
    .with({ themeColor: P.string.minLength(1), primaryColor: P.optional(undefined) }, () => true)
    .with({ theme: P.string.minLength(1), themeMode: P.optional(undefined) }, () => true)
    .with({ theme: P.string.minLength(1), variant: P.optional(undefined) }, () => true)
    .with({ theme: P.string.minLength(1), fontFamily: P.optional(undefined) }, () => true)
    .with({ theme: P.string.minLength(1), borderRadiusScale: P.optional(undefined) }, () => true)
    .with({ botName: P.string.minLength(1), botDisplayName: P.optional(undefined) }, () => true)
    .with({ avatarUrl: P.string.minLength(1), botAvatarUrl: P.optional(undefined) }, () => true)
    .with({ botConversationDescription: P.string.minLength(1), botDescription: P.optional(undefined) }, () => true)
    .with({ phoneNumber: P.string.minLength(1), descriptionPhoneNumber: P.optional(undefined) }, () => true)
    .with({ emailAddress: P.string.minLength(1), descriptionEmailAddress: P.optional(undefined) }, () => true)
    .with({ website: P.string.minLength(1), descriptionWebsiteUrl: P.optional(undefined) }, () => true)
    .with({ termsConditions: P.string.minLength(1), termsConditionsUrl: P.optional(undefined) }, () => true)
    .with({ privacyPolicy: P.string.minLength(1), privacyPolicyUrl: P.optional(undefined) }, () => true)
    .with({ composerPlaceholder: P.string.minLength(1), botComposerPlaceholder: P.optional(undefined) }, () => true)
    .with({ additionalStyles: P.string.minLength(1), additionalStylesheet: P.optional(undefined) }, () => true)
    .with({ showPoweredBy: P.boolean, showPoweredByBotpress: P.optional(undefined) }, () => true)
    .otherwise(() => false)
}

export type WebchatIntegrationConfig = z.infer<typeof webchatIntegrationConfigSchema>
