import { Link as ThemeLink, createFileRoute, useNavigate, useRouter } from '@tanstack/react-router'
import {
  Button,
  ContextMenu,
  DropdownMenu,
  EmptyState,
  IconButton,
  Link,
  List,
  MenuItem,
  Select,
  SelectMenuItems,
} from '~/elementsv2'
import {
  useCreateWorkspaceMember,
  useDeleteWorkspaceMember,
  useIsAuthorized,
  useUpdateWorkspaceMember,
  useUsage,
  useWorkspaceMembers,
} from '~/hooks'
import { Badge, Box, Container, Flex, Separator, Text, TextField, Tooltip } from '@radix-ui/themes'
import { Boundrary, DialogFooter, UsageProgressBar, UserAvatar } from '~/componentsV2'
import { Changelog } from '~/assets'
import { Skeleton } from '~/elements'
import { ClientReturn } from 'botpress-client'
import { useSuspenseQuery as useTanstackSuspenseQuery } from '@tanstack/react-query'

import { HiOutlineEllipsisVertical, HiPlus } from 'react-icons/hi2'
import { RoleOptions } from '~/features/users/components'
import {
  getQueryOptions,
  showConfirmationDialog,
  showCustomDialog,
  showSuccessToast,
  showUpsellDialog,
  useSuspenseQuery,
} from '~/services'
import { WorkspaceMemberObject } from '~/features/workspaces/types'
import { Role } from '~/features/users/types'
import { useState } from 'react'
import { SidebarLayout } from '~/layouts'
import { QUOTA_TYPE_USER_FACING_PROPERTIES_MAP } from '~/features/usage/constants'

type Member = ClientReturn<'listWorkspaceMembers'>['members'][number]

export const Route = createFileRoute('/workspaces/$workspaceId/members')({
  component: Component,
})

function Component() {
  const router = useRouter()
  const workspaceId = Route.useParams().workspaceId
  const { workspace_member_count } = useUsage({ workspaceId, quotas: ['workspace_member_count'] })

  return (
    <Container size="4" p="5">
      <Flex direction="column" gap="4">
        <Flex align="center" className="px-4">
          <Text size="5" weight="bold">
            Members
          </Text>
          <Button
            className="ml-auto"
            onClick={() =>
              workspace_member_count.value < workspace_member_count.quota
                ? showCustomDialog({ title: 'Add new member', content: <AddMemberDialog workspaceId={workspaceId} /> })
                : showUpsellDialog({
                    quota: 'workspace_member_count',
                    workspaceId,
                    onConfirm: () =>
                      void router.navigate({
                        to: '/workspaces/$workspaceId/settings/billing',
                        params: { workspaceId },
                      }),
                  })
            }
          >
            <HiPlus />
            Invite member
          </Button>
        </Flex>
        <Separator size="4" />
        <Boundrary
          className="h-96"
          suspenseFallback={
            <Flex direction={'column'} gap={'4'}>
              <Skeleton height={32} width={128} className="self-end" />
              <List items={Array(5).fill(null)}>{<AuditRowSkeleton />}</List>
            </Flex>
          }
        >
          <SidebarLayout
            main={<Members />}
            rightSidebar={
              <Flex direction={'column'} gap={'5'}>
                <Flex direction={'column'} gap={'1'}>
                  <Text size={'2'}>
                    <UsageProgressBar
                      title={<Text weight={'medium'}>Your Plan Limit</Text>}
                      description={
                        <Text size={'1'} color="gray">
                          {QUOTA_TYPE_USER_FACING_PROPERTIES_MAP['workspace_member_count'].description}
                        </Text>
                      }
                      {...workspace_member_count}
                    />
                  </Text>

                  <Link to={'/workspaces/$workspaceId/settings/billing'} params={{ workspaceId }} size="2" color="blue">
                    {workspace_member_count.value >= workspace_member_count.quota ? (
                      <Button size={'1'} variant="soft" color="red">
                        Get more seats
                      </Button>
                    ) : (
                      'Get more seats'
                    )}
                  </Link>
                </Flex>
                <Separator size={'4'} />
                <Flex direction={'column'} gap="3">
                  <Text weight={'medium'} size={'2'}>
                    Need help? Invite an Expert
                  </Text>
                  <Text color="gray" size={'1'}>
                    Team up with a Botpress expert and get help to design, build and optimize your chatbot or
                    integration.
                  </Text>
                  <ThemeLink href="https://botpress.com/experts/projects/create">
                    <Button size={'1'} variant="soft" color="grass">
                      Find your expert
                    </Button>
                  </ThemeLink>
                </Flex>
              </Flex>
            }
          />
        </Boundrary>
      </Flex>
    </Container>
  )
}

const Members = () => {
  const workspaceId = Route.useParams().workspaceId
  const workspaceMembers = useSuspenseQuery('workspaces_/$workspaceId_/members', { workspaceId }).data

  return workspaceMembers.length ? (
    <Flex direction={'column'} gap={'4'}>
      <List items={workspaceMembers}>{(member) => <MemberRow {...member} />}</List>
    </Flex>
  ) : (
    <EmptyState
      p={'8'}
      iconSize={6}
      icon={Changelog}
      title="You don't have any audits"
      description="You have not performed any actions that are audited. Keep using Botpress to see your actions here."
      primaryAction={
        <ThemeLink to="/workspaces/$workspaceId" params={{ workspaceId }}>
          <Button size={'2'}>Back to workspace</Button>
        </ThemeLink>
      }
    />
  )
}

type AddMemberDialogProps = {
  workspaceId: string
}
const AddMemberDialog = ({ workspaceId }: AddMemberDialogProps) => {
  const { plan } = useTanstackSuspenseQuery(getQueryOptions('workspaces_/$workspaceId_', { workspaceId })).data
  const [role, setRole] = useState<Role>(plan === 'community' ? 'administrator' : 'viewer')
  const [email, setEmail] = useState('')
  const { mutate: createWorkspaceMember } = useCreateWorkspaceMember()

  const roleMenu: SelectMenuItems[] = RoleOptions.map((option) => ({
    type: 'item',
    value: option.role,
    disabled: option.role === role,
    content: (
      <Flex direction={'column'}>
        <Tooltip content={option.description} className="max-w-64" side="right" delayDuration={500} sideOffset={16}>
          <Text>{option.label}</Text>
        </Tooltip>
      </Flex>
    ),
  }))

  return (
    <Flex direction={'column'} gap={'2'}>
      <Flex direction={'column'} gap={'4'}>
        <Text size={'1'}>An invitation will be sent to the email address you provide.</Text>
        <Flex gap={'4'}>
          <TextField.Root
            className="grow"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            placeholder="example@example.com"
          />
          {/* TODO: Add a tooltip option to the select component, this was done in a hurry */}
          {plan === 'community' ? (
            <Tooltip content={'Role-based access control is only available in paid plans.'}>
              <Button color="gray" disabled>
                {role}
              </Button>
            </Tooltip>
          ) : (
            <Select
              value={role}
              onValueChange={(newRole) => setRole(newRole as Role)}
              variant="soft"
              color="gray"
              items={roleMenu}
            />
          )}
        </Flex>
      </Flex>

      <DialogFooter
        //TODO: decide how to handle form validation in the modal
        disabled={!email}
        onConfirm={() => {
          createWorkspaceMember({
            workspaceId,
            email,
            role,
            options: {
              onSettled: async () => {
                // setSubmitting(false)
              },
              onSuccess: async () => {
                showSuccessToast(`${email} has been 'invited' to this workspace as ${role}.`)
              },
            },
          })
        }}
      />
    </Flex>
  )
}

const MemberRow = (member: Member) => {
  const { email, role, id, userId } = member
  const { workspaceId } = Route.useParams()
  const { user: currentUser } = Route.useRouteContext()
  const isAuthorized = useIsAuthorized({ workspaceId, userId: currentUser.id })
  const navigate = useNavigate()

  const { mutate: updateWorkspaceMember } = useUpdateWorkspaceMember()
  const { mutate: deleteWorkspaceMember } = useDeleteWorkspaceMember()
  const { data: members } = useWorkspaceMembers({ workspaceId })
  const { plan } = useTanstackSuspenseQuery(getQueryOptions('workspaces_/$workspaceId_', { workspaceId })).data

  const currentUserMember = members?.find((m) => m.userId === currentUser.id)

  const onMemberRemoved = (removedMember: WorkspaceMemberObject) => {
    if (currentUser.id === removedMember.userId) {
      // If the user removes themself from the workspace, redirect to home.
      void navigate({ to: '/', replace: true })
      return
    }

    showSuccessToast(`${removedMember.email} has been removed from this workspace.`)
  }

  const menu: MenuItem[] = [
    {
      type: 'submenu',
      content: 'Change role',
      disabled: role === 'owner',
      items: [
        {
          type: 'radioGroup',
          value: role,
          items: RoleOptions.map((option) => ({
            type: 'radioItem',
            value: option.role,
            disabled: option.role === role || (option.role !== 'administrator' && plan === 'community'),
            content: (
              <Flex direction={'column'}>
                <Tooltip
                  content={option.description}
                  className="max-w-64"
                  side="right"
                  delayDuration={500}
                  sideOffset={16}
                >
                  <Text>{option.label}</Text>
                </Tooltip>
              </Flex>
            ),
            onSelect: () =>
              showConfirmationDialog({
                title: 'Change role',
                content: (
                  <Text size={'2'}>
                    Are you sure you want to change the role of <Text weight={'bold'}>{email} </Text>
                    from <Text weight={'bold'}>{role} </Text>
                    to <Text weight={'bold'}>{option.role} </Text>?
                  </Text>
                ),
                onConfirm: () =>
                  updateWorkspaceMember({
                    id,
                    workspaceId,
                    role: option.role,
                    onSuccess: async () => {
                      showSuccessToast(`The role of ${email} has been successfully changed to ${option.role}.`)
                    },
                  }),
              }),
          })),
        },
      ],
    },
    { type: 'separator' },
    {
      type: 'item',
      content: 'Remove',
      color: 'red',
      onSelect: () =>
        showConfirmationDialog({
          variant: 'danger',
          title: 'Remove member',
          content:
            userId === currentUser.id ? (
              <Text size={'2'}>
                You are about to <Text weight={'bold'}>remove yourself</Text> from the workspace. You will no longer
                have access to this workspace.
                <br />
                <br /> Are you sure you want to proceed?
              </Text>
            ) : (
              <Text size={'2'}>
                Are you sure you want to remove <Text weight={'bold'}>{email}</Text> from the workspace?
              </Text>
            ),
          onConfirm: () =>
            deleteWorkspaceMember({
              workspaceId,
              id,
              requesterMemberId: currentUserMember?.id ?? '',
              onSuccess: async () => {
                onMemberRemoved(member)
              },
            }),
        }),
    },
  ]

  return (
    <ContextMenu disabled={!isAuthorized('workspace.manageMembers')} color="gray" variant="soft" content={menu}>
      <Flex p={'3'} align={'center'} gap={'4'}>
        <UserAvatar size={'2'} userId={userId} workspaceId={workspaceId} />
        <Text size={'2'} weight={'medium'}>
          {email}
        </Text>
        {currentUser.email === email && <Badge>You</Badge>}
        {role === 'owner' && <Badge color={'gray'}>Owner</Badge>}
        <Text size={'2'} color={'gray'} ml={'auto'}>
          {role}
        </Text>
        {isAuthorized('workspace.manageMembers') ? (
          <DropdownMenu color="gray" variant="soft" content={menu}>
            <IconButton variant={'minimal'} color="gray" icon={HiOutlineEllipsisVertical} />
          </DropdownMenu>
        ) : (
          <Box />
        )}
      </Flex>
    </ContextMenu>
  )
}

const AuditRowSkeleton = () => {
  return (
    <Flex direction={'column'}>
      <Flex align={'center'} p={'3'} gap={'4'}>
        <Skeleton height={32} width={32} />
        <Skeleton height={8} width={200} />
        <Skeleton className="ml-auto" width={96} />
      </Flex>
    </Flex>
  )
}
