import React from 'react'
import { BreadcrumbPage, Breadcrumbs, IconButton } from '../elementsv2'
import { useQuery } from '@tanstack/react-query'
import { botQueryOptions, listBotsQueryOptions, publicWorkspaceQueryOptions, workspacesQueryOptions } from '../queries'
import { useAppStore } from '~/stores'
import { Badge } from '@radix-ui/themes'
import { HiOutlineChevronUpDown } from 'react-icons/hi2'
import { WorkspaceSelectorMenu, BotSelectorMenu } from '~/componentsV2'

type Props = {
  workspaceId?: string
  botId?: string
}

export const NavbarBreadcrumbs = ({ botId, workspaceId }: Props) => {
  const { data: workspace } = useQuery({
    ...publicWorkspaceQueryOptions(workspaceId ?? ''),
    enabled: !!workspaceId,
  })

  const { data: workspaces } = useQuery(workspacesQueryOptions())
  const { data: bots } = useQuery(listBotsQueryOptions(workspaceId ?? ''))

  const plan = useAppStore((s) => s.activeWorkspace)?.plan

  const bot = useQuery({
    ...botQueryOptions({ botId: botId ?? '', workspaceId: workspaceId ?? '' }),
    enabled: !!botId && !!workspaceId,
  }).data

  const breadcrumbsPages: BreadcrumbPage[] = []

  if (workspace) {
    breadcrumbsPages.push({
      name: workspace?.name,
      to: bot ? '/workspaces/$workspaceId' : undefined,
      params: { workspaceId },
      search: {},
      trailing: (
        <>
          {plan && plan !== 'community' && (
            <Badge className="-mb-0.5 capitalize" color={plan === 'team' ? 'grass' : 'iris'}>
              {plan}
            </Badge>
          )}
          {workspaces && (
            <WorkspaceSelectorMenu workspaces={workspaces}>
              <IconButton variant="minimal" mr={'-2'} size={'1'} color="gray" icon={HiOutlineChevronUpDown} />
            </WorkspaceSelectorMenu>
          )}
        </>
      ),
    })
  }
  if (bot) {
    breadcrumbsPages.push({
      name: bot.name,
      params: {},
      search: {},
      trailing: bots ? (
        <BotSelectorMenu bots={bots}>
          <IconButton variant="minimal" mr={'-2'} size={'1'} color="gray" icon={HiOutlineChevronUpDown} />
        </BotSelectorMenu>
      ) : undefined,
    })
  }

  return <Breadcrumbs gap={'2'} pages={breadcrumbsPages} />
}
