import { Flex, TextField } from '@radix-ui/themes'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { cn, startsWithAny } from '~/utils'
import { Button, DropdownMenu, Icon, MenuItem } from '../elementsv2'
import { HiMagnifyingGlass, HiMiniChevronDown } from 'react-icons/hi2'
import { match, P } from 'ts-pattern'

export type FilterMenuInput = {
  name: string
  type?: 'string' | 'object' | 'array'
  value?: string | FilterMenuInput[]
  paramName?: string
  icon?: React.ReactNode
}

type Props = { className?: string; filters: FilterMenuInput[] }

export const QueryParamBuilder = ({ className, filters, ...props }: Props) => {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const queryParams = useSearch({ strict: false })
  const [filterInput, setFilterInput] = useState(
    Object.entries(queryParams)
      .map(([key, value]) => {
        return match(value)
          .with(P.array(), (stringArray) => stringArray.map((v) => `${key}:${v}`).join(' '))
          .with(P.string, (v) => `${key}:${v}`)
          .otherwise((paramRecord) => {
            return Object.entries(paramRecord as any)
              .map(([k, v]) => `${key}:${k}:${v}`)
              .join(' ')
          })
      })
      .join(' ')
  )

  const navigate = useNavigate()
  const updateSearchParams = (searchParams: Record<string, string | string[] | Record<string, string>>) => {
    void navigate({
      to: '.',
      params: (current: Record<string, any>) => current,
      search: () => searchParams,
    })
  }

  const addFilter = useCallback(
    ({ filter, value }: { filter: string; value?: string }) => {
      setFilterInput((prevInput) => `${prevInput.trim()} ${filter}:${value ?? ''}`.trim())
    },
    [setFilterInput]
  )

  useEffect(() => {
    setFilterInput(
      Object.entries(queryParams)
        .map(([key, value]) => {
          return match(value)
            .with(P.array(), (stringArray) => stringArray.map((v) => `${key}:${v}`).join(' '))
            .with(P.string, (v) => `${key}:${v}`)
            .otherwise((paramRecord) => {
              return Object.entries(paramRecord as Record<string, string>)
                .map(([k, v]) => `${key}:${k}:${v}`)
                .join(' ')
            })
        })
        .join(' ')
    )
  }, [queryParams])

  function buildMenuItem(filter: FilterMenuInput): MenuItem {
    const { name, value, paramName, icon } = filter
    if (Array.isArray(value)) {
      return {
        type: 'submenu',
        content: name,
        items: value.map((f) => buildMenuItem(f)),
        leadingIcon: icon,
      }
    }
    return {
      type: 'item',
      content: name,
      leadingIcon: icon,
      onSelect: () => {
        addFilter({ filter: paramName ?? '', value })
      },
    }
  }

  function parseSearch(searchString: string) {
    const searchKeywords = filters.flatMap(extractKeywords)
    const { parsedParams, search } = searchString.split(' ').reduce(
      (acc, curr) => {
        if (
          startsWithAny(
            curr,
            searchKeywords.map((k) => `${k}:`)
          )
        ) {
          const [keyword, ...value] = curr.split(':')
          if (!keyword) {
            acc.search.push(curr)
            return acc
          }
          const type = getKeywordType(filters, keyword)
          if (type === 'array') {
            acc.parsedParams[keyword as string] = [
              ...((acc.parsedParams[keyword as string] ?? []) as string[]),
              value.join(':'),
            ]
          } else if (type === 'object') {
            const [key, ...rest] = value
            acc.parsedParams[keyword as string] = {
              ...((acc.parsedParams[keyword as string] ?? {}) as Record<string, string>),
              [key as string]: rest.join(':'),
            }
          } else {
            acc.parsedParams[keyword as string] = value.join(':')
          }
        } else {
          acc.search.push(curr)
        }
        return acc
      },
      { parsedParams: {}, search: [] } as {
        parsedParams: Record<string, string | string[] | Record<string, string>>
        search: string[]
      }
    )
    return { parsedParams, search: search.join(' ') }
  }

  const searchFilterMenu: MenuItem[] = [{ type: 'label', label: 'Filter by' }, ...filters.map(buildMenuItem)]

  return (
    <Flex {...props} className={cn(className)} gap={'2'}>
      <TextField.Root
        ref={searchInputRef}
        value={filterInput}
        onChange={(e) => {
          setFilterInput(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const { parsedParams } = parseSearch(filterInput)
            updateSearchParams(parsedParams)
          }
        }}
        className="grow"
      >
        <TextField.Slot className="pl-0">
          <DropdownMenu
            content={searchFilterMenu}
            color="gray"
            size={'2'}
            onCloseAutoFocus={(e) => {
              e.preventDefault()
              searchInputRef.current?.focus()
              searchInputRef.current?.setSelectionRange(filterInput.length, filterInput.length)
            }}
          >
            <Button
              variant="surface"
              color="gray"
              className="rounded-r-none"
              trailing={<Icon icon={HiMiniChevronDown} />}
              onClick={() => {}}
            >
              Filters
            </Button>
          </DropdownMenu>
          <Icon icon={HiMagnifyingGlass} color="gray" />
        </TextField.Slot>
      </TextField.Root>
    </Flex>
  )
}

function extractKeywords(filter: FilterMenuInput): string[] {
  const { value, paramName } = filter
  if (Array.isArray(value)) {
    return value.flatMap((f) => extractKeywords(f))
  }
  return paramName ? [paramName] : []
}

function getKeywordType(filters: FilterMenuInput[], keyword?: string): 'string' | 'object' | 'array' | undefined {
  if (!keyword) {
    return 'string'
  }

  for (const filter of filters) {
    if (Array.isArray(filter.value)) {
      const type = getKeywordType(filter.value, keyword)
      if (type) {
        return type
      }
    }

    if (filter.paramName === keyword) {
      return filter.type ?? 'string'
    }
  }
}
