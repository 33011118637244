import { createFileRoute } from '@tanstack/react-router'
import { Button, TextInput } from '~/elementsv2'
import { ConfigSection } from '~/features/webchat/components'
import { useWebchatConfig } from '~/hooks'
import { queryClient } from '~/providers/ReactQuery'
import { getQueryKey } from '~/services'
import { Flex, Text } from '@radix-ui/themes'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/v1/theme')({
  component: Component,
})

function Component() {
  const { workspaceId, botId } = Route.useParams()
  const { config, isPending, setConfig, updateWebchatIntegration } = useWebchatConfig({ botId, workspaceId })

  return (
    <Flex direction={'column'} gap={'6'}>
      <ConfigSection title="Styles" description="Custom styles for your bot.">
        <Flex gap="4" direction={'column'}>
          <TextInput
            label="Stylesheet URL"
            value={config.stylesheet}
            onChange={(e) => setConfig((prev) => ({ ...prev, stylesheet: e.target.value }))}
          />
          <Button
            loading={isPending}
            onClick={() => {
              updateWebchatIntegration(config)
              void queryClient.invalidateQueries({
                queryKey: getQueryKey('workspaces_/$workspaceId_/bots_/$botId_/webchat', {
                  workspaceId,
                  botId,
                }),
              })
            }}
            className="self-end"
          >
            <Text>Save</Text>
          </Button>
        </Flex>
      </ConfigSection>
    </Flex>
  )
}
