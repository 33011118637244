// this file was automatically generated, do not edit
/* eslint-disable */

export interface PatchStateRequestHeaders {}

export interface PatchStateRequestQuery {}

export interface PatchStateRequestParams {
  type: "conversation" | "user" | "bot" | "integration" | "task" | "workflow";
  id: string;
  name: string;
}

export interface PatchStateRequestBody {
  /**
   * Payload is the content of the state defined by your bot.
   */
  payload: {
    [k: string]: any;
  };
}

export type PatchStateInput = PatchStateRequestBody & PatchStateRequestHeaders & PatchStateRequestQuery & PatchStateRequestParams

export type PatchStateRequest = {
  headers: PatchStateRequestHeaders;
  query: PatchStateRequestQuery;
  params: PatchStateRequestParams;
  body: PatchStateRequestBody;
}

export const parseReq = (input: PatchStateInput): PatchStateRequest & { path: string } => {
  return {
    path: `/v1/chat/states/${encodeURIComponent(input['type'])}/${encodeURIComponent(input['id'])}/${encodeURIComponent(input['name'])}`,
    headers: {  },
    query: {  },
    params: { 'type': input['type'], 'id': input['id'], 'name': input['name'] },
    body: { 'payload': input['payload'] },
  }
}

export interface PatchStateResponse {
  /**
   * The state object represents the current payload. A state is always linked to either a bot, a conversation or a user.
   */
  state: {
    /**
     * Id of the [State](#schema_state)
     */
    id: string;
    /**
     * Creation date of the [State](#schema_state) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the [State](#schema_state) in ISO 8601 format
     */
    updatedAt: string;
    /**
     * Id of the [Bot](#schema_bot)
     */
    botId: string;
    /**
     * Id of the [Conversation](#schema_conversation)
     */
    conversationId?: string;
    /**
     * Id of the [User](#schema_user)
     */
    userId?: string;
    /**
     * Name of the [State](#schema_state) which is declared inside the bot definition
     */
    name: string;
    /**
     * Type of the [State](#schema_state) represents the resource type (`conversation`, `user`, `bot`, `task`, `integration` or `workflow`) that the state is related to
     */
    type: "conversation" | "user" | "bot" | "task" | "integration" | "workflow";
    /**
     * Payload is the content of the state defined by your bot.
     */
    payload: {
      [k: string]: any;
    };
  };
}

