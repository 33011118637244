import {
  HiMiniBars3,
  HiOutlineArrowTrendingUp,
  HiOutlineChartBar,
  HiOutlineChatBubbleLeft,
  HiOutlineChatBubbleLeftRight,
  HiOutlineClipboardDocumentList,
  HiOutlineCog6Tooth,
  HiOutlineCommandLine,
  HiOutlineComputerDesktop,
  HiOutlineCubeTransparent,
  HiOutlineExclamationTriangle,
  HiOutlineHome,
  HiOutlineUsers,
  HiOutlineVariable,
} from 'react-icons/hi2'

import { Boundrary, Navbar, NavbarItem } from '../componentsV2'
import { Flex, Text, Badge } from '@radix-ui/themes'
import { DropdownMenu, IconButton } from '~/elementsv2'
import { useNavigate } from '@tanstack/react-router'
import { NotificationBadge } from '~/features/issues/componentsV2'
import { useFeature } from '~/hooks'

type SecondLevelNavProps = {
  type: 'workspace' | 'bot' | 'none'
  currentWorkspaceId?: string
  currentBotId?: string
  currentRouteId?: string
}

const workspaceNavbarItems: NavbarItem[] = [
  { text: 'Home', leadingIcon: HiOutlineHome, to: '/workspaces/$workspaceId/home' },
  { text: 'Members', leadingIcon: HiOutlineUsers, to: '/workspaces/$workspaceId/members' },
  { text: 'Audits', leadingIcon: HiOutlineClipboardDocumentList, to: '/workspaces/$workspaceId/audits' },
  { text: 'Your Integrations', leadingIcon: HiOutlineCubeTransparent, to: '/workspaces/$workspaceId/integrations' },
  { text: 'Usage', leadingIcon: HiOutlineChartBar, to: '/workspaces/$workspaceId/usage' },
  { text: 'Settings', leadingIcon: HiOutlineCog6Tooth, to: '/workspaces/$workspaceId/settings' },
]

const botNavbarItems: NavbarItem[] = [
  { text: 'Overview', leadingIcon: HiOutlineHome, to: '/workspaces/$workspaceId/bots/$botId/overview' },
  {
    text: 'Webchat',
    leadingIcon: HiOutlineChatBubbleLeft,
    trailingItem: (
      <Badge color="green" variant="outline">
        New
      </Badge>
    ),
    to: '/workspaces/$workspaceId/bots/$botId/webchat/v2/general',
  },
  { text: 'Logs', leadingIcon: HiOutlineCommandLine, to: '/workspaces/$workspaceId/bots/$botId/logs' },
  {
    text: 'Conversations',
    leadingIcon: HiOutlineChatBubbleLeftRight,
    to: '/workspaces/$workspaceId/bots/$botId/conversations',
  },
  { text: 'Analytics', leadingIcon: HiOutlineArrowTrendingUp, to: '/workspaces/$workspaceId/bots/$botId/analytics' },
  { text: 'Events', leadingIcon: HiOutlineComputerDesktop, to: '/workspaces/$workspaceId/bots/$botId/events' },
  {
    text: 'Issues',
    leadingIcon: HiOutlineExclamationTriangle,
    trailingItem: (
      <Boundrary loader={null} onError={() => null}>
        <NotificationBadge />
      </Boundrary>
    ),
    to: '/workspaces/$workspaceId/bots/$botId/issues',
  },
  {
    text: 'Configuration variables',
    leadingIcon: HiOutlineVariable,
    to: '/workspaces/$workspaceId/bots/$botId/configuration-variables',
  },
]

export const SecondLevelNav = ({
  type,
  currentWorkspaceId: workspaceId,
  currentBotId: botId,
  currentRouteId,
}: SecondLevelNavProps) => {
  const navigate = useNavigate()
  const hitlEnabled = useFeature('dashboard-hitl')

  if (type === 'none') {
    return null
  }

  const hitlNavItem: NavbarItem = {
    text: 'HITL',
    leadingIcon: HiOutlineUsers,
    trailingItem: (
      <Badge color="green" variant="outline">
        New
      </Badge>
    ),
    to: '/workspaces/$workspaceId/bots/$botId/hitl',
  }

  const items = (
    type === 'workspace' ? workspaceNavbarItems : hitlEnabled ? [...botNavbarItems, hitlNavItem] : botNavbarItems
  ).map<NavbarItem>((item) => ({
    ...item,
    active: currentRouteId?.includes(item.to ?? 'not-a-path'),
  }))

  const activeItem = items.find((item) => item.active)
  return (
    <>
      <Navbar className="hidden lg:flex" workspaceId={workspaceId} botId={botId} items={items} direction="row" />
      <Flex className="lg:hidden">
        {activeItem && (
          <Flex gap={'1'} align={'center'} className="text-gray-11">
            {activeItem.leadingIcon && <activeItem.leadingIcon size={18} strokeWidth={2} />}
            <Text weight={'medium'}>{activeItem.text}</Text>
          </Flex>
        )}
        <DropdownMenu
          variant="soft"
          color="gray"
          content={[
            {
              type: 'radioGroup',
              value: activeItem?.to,
              items: items.map((i) => ({
                type: 'radioItem',
                value: i.to!,
                content: i.text,
                onSelect: () => {
                  // @ts-ignore ==> NavigateProps['to'] and LinkProps['to'] don't perfectly align but works for us here
                  void navigate({ to: i.to, params: { workspaceId, botId } })
                },
              })),
            },
          ]}
        >
          <IconButton size={'2'} variant="outline" color="gray" icon={HiMiniBars3} className="ml-auto" />
        </DropdownMenu>
      </Flex>
    </>
  )
}
